import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { linkUnderline } from 'style/global.styles';

export const root = css`
  min-height: 364px;
  padding: calc(var(--unit) * 10);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: border 0.2s;
  border-radius: 3px;
  width: 100%;

  h3 {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`;

export const rootTheme = (theme: 'light' | 'dark') => {
  if (theme === 'dark') {
    return css`
      ${root};
      background: hsl(var(--color-grayscale-9));
      color: white;
    `;
  }

  return css`
    ${root};
    border: 1px solid hsl(var(--color-borderUltraLight-9));

    &:hover {
      border: 1px solid hsl(var(--color-borderBlack-2));
    }
  `;
};

export const openingInfo = css`
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--unit) * 2);

  p {
    margin: 0 calc(var(--unit) * 4) 0 0;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    color: hsl(var(--color-grayscale-4));
  }
`;

export const avatar = css`
  width: calc(var(--unit) * 12);
  height: calc(var(--unit) * 12);
  min-width: calc(var(--unit) * 12);

  span {
    color: white;
    ${font.size.base}
  }
`;

export const avatarWithBackground = css`
  ${avatar};
  background: hsl(var(--color-error-7));
`;

export const name = css`
  display: flex;
  align-items: center;
  color: inherit;

  p {
    margin-left: calc(var(--unit) * 3);
    ${linkUnderline};
  }
`;

export const nameLight = css`
  ${name};

  p {
    &:after {
      background: hsl(var(--color-text-1));
    }
  }
`;

export const openingName = css`
  ${linkUnderline};

  a {
    color: inherit;
  }
`;

export const openingNameLight = css`
  ${openingName};

  &:after {
    background: hsl(var(--color-text-1));
  }
`;
